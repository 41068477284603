.logoHeader {
  width: 40%;
}

.marcaHeader {
  width: 70%;
}

.marcaHeaderPreschool {
  width: 50%;
}

.brainyFunOnLine {
  width: 70%;
}

.altBakcground {
  background-color: white;
}

.whiteFont {
  color: white;
  font-size: 5rem;
}

.whiteText {
  color: white;
}

.altColorText {
  color: #e74e94;
}

.altColorText2 {
  color: #35a7df;
}

.footerLogo {
  width: 40%;
}

.jumboAltColor{
  background-color: #e74e94;
}

.jumboAltColor2{
  background-color: #35a7df;
}

.altColor{
  border-top: 1px solid rgba(217, 93, 147,.9);
}

.bordered{
  border-radius:5px;
}

.btn-matriculas{
  opacity: 0.8;
}

.btn-matriculas:hover{
  opacity: 1;
}

.GVIS-Background {
  background-color: #232f3e;
}

@media (max-width: 768px) {
  .brainyFunOnLine {
    width: 30%;
  }
  .logoHeader {
    width: 20%;
  }
  .footerLogo {
    width: 50%;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.2rem;
  }
}